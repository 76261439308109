<template>
  <div class="profile-post-builder">
    <Panel class="!pt-0 !mt-0 !px-1 md:!px-2">
      <PostForm
        :scheduled="scheduled"
        @broadcast-feed-update="relayPostCreated"
      />
    </Panel>
  </div>
</template>

<script setup>
  const props = defineProps({
    scheduled: {
      type: Boolean,
      default: false,
    },
  })

  const emit = defineEmits(["post-created"])

  const relayPostCreated = (newPost) => {
    emit("post-created", newPost)
  }
</script>
